import React from "react";
import styled from "styled-components";
import Text from "../editorial/Text";
import Spacer from "../layout/Spacer";
import AltImage from "../AltImage";
import Link from "../localization/Link";
import Conditional from "../layout/Conditional";
import { useLanguageContext } from "../../../contexts/LanguageContext";

const Comp = styled.div`
  display: flex;
  flex-direction: ${p => p.right ? "row-reverse" : p.col ? "column-reverse" : "row"};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  flex: 1;
`;

const MoreLink = styled(Text)`
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;

const Image = styled(AltImage)`
  //min-width: 26.875rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column-reverse;
    width: 100%;
    height: 255px;
  }
`;

const ImageContainer = styled.div`
  max-height: 15.9375rem;
  min-height: 15.9375rem;
  flex: 0.7;
`;

const LearnMoreLink = styled(Link)`
  color: ${({ theme }) => theme.colors.main02};

  :hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const LearnPreview = (props) => {
	const { baseLink, data, more, news, right, col, desc } = props;
	const date = new Date(data.date);
	const language = useLanguageContext();
	const day = data.dateType !== "month" ? { day: "numeric" } : null;
	const time = data.dateType === "dayTime" ? { hour: "numeric", minute: "numeric" } : null;

	const dateFormatter = new Intl.DateTimeFormat(
		language.lang,
		{
			month: "long",
			...day,
			year: "numeric",
			...time
		});
	// { dateStyle: data.dateType === "month" ? "month" :"long" });
	// console.log(data.internal.type);


	return (
		<Comp right={right} col={col}>
			<Left>
				<Text s="medium" w="medium">
					<Link to={`${baseLink}/${data.slug.current}`}>
						{data.title}
					</Link>
				</Text>

				{!desc ? null :
					<>
						<Text s="medium">
							{data.description}
						</Text>
						<Spacer s="small" />
					</>
				}

				{news && data.date && data.dateType !== "none" ?
					<>
						<Text s="medium">
							{dateFormatter.format(date)}
						</Text>
						<Spacer s="small" />
					</> : null}

				<Conditional desktop>
					<MoreLink s="medium" c="main02" style={{ width: "fit-content" }}>
						<LearnMoreLink to={`${baseLink}/${data.slug.current}`}>
							{more}
						</LearnMoreLink>
					</MoreLink>
				</Conditional>
			</Left>

			<Conditional desktop>
				<Spacer s="medium" />
			</Conditional>

			<Conditional mobile>
				<Spacer s="small" />
			</Conditional>

			{data.image ?
				<ImageContainer>
					<Link to={`${baseLink}/${data.slug.current}`}>
						<Image data={data.image} />
					</Link>
				</ImageContainer>
				: null}
		</Comp>
	);
}
	;

export default LearnPreview;
