import React from "react";
import styled from "styled-components";
import Text from "../editorial/Text";
import Spacer from "../layout/Spacer";
import slugify from "slugify";

const Comp = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.main01};
  //display: block;
`;

const HiddenAnchor = styled.div`
  //display: block;
  position: relative;
  top: -150px;
  visibility: hidden;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    top: -100px;
  }
`;

const CategoryTitle = (props) => {
	return (
		<>
			<HiddenAnchor id={slugify(props.title)} />
			<Comp>
				<Text s="h2" c="main01" style={{ textTransform: "capitalize" }}>
					{props.title}
				</Text>
			</Comp>
			<Spacer s="medium" />
		</>
	);
};

export default CategoryTitle;