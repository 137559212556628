import React from "react";
import Section from "../layout/Section";
// import LearnPreview from "./LearnPreview";
import Spacer from "../layout/Spacer";
import Conditional from "../layout/Conditional";
import CategoryTitle from "./CategoryTitle";
import ItemPreview from "./ItemPreview";

const LearnList = (props) => {
	const { baseLink, data, more } = props;

	const filesCat = data.map((item, index) => {
		const theFiles = item.items.map((item, index) => {
			return (
				<React.Fragment key={index}>
					<ItemPreview data={item} more={more} baseLink={baseLink} desc news />

					<Conditional desktop>
						<Spacer s="large" />
					</Conditional>

					<Conditional mobile>
						<Spacer s="medium" />
					</Conditional>
				</React.Fragment>
			);
		});

		return (
			<React.Fragment key={index}>
				<CategoryTitle title={item.title} />
				{theFiles}
			</React.Fragment>
		);
	});

	// const files = data.map((item, index) => {
	// 	return (
	// 		<React.Fragment key={index}>
	// 			<LearnPreview data={item} more={more} />
	//
	// 			<Conditional desktop>
	// 				<Spacer s="large" />
	// 			</Conditional>
	//
	// 			<Conditional mobile>
	// 				<Spacer s="medium" />
	// 			</Conditional>
	// 		</React.Fragment>
	// 	);
	// });

	return (
		<Section m="medium">
			{filesCat}
			<Spacer s="medium" />
		</Section>
	);
};

export default LearnList;
