import React from "react";
import styled from "styled-components";
import TagList from "../_global/lists/TagList";
import Text from "../_global/editorial/Text";
import Spacer from "../_global/layout/Spacer";

const Comp = styled.div`
  //display: flex;
  //justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
`;

const YearsContainer = styled.div`
  display: flex;
`;

const YearItem = styled(Text)`
  cursor: pointer;
  font-weight: ${p => p.selected ? "600" : "normal"};
  padding: ${p => p.selected ? "0" : "0 2px"};

  :hover {
    color: ${({ theme }) => theme.colors.main02};
  }
`;

const Years = (props) => {
	const { data, year, years, setYear } = props;

	const yearsDisplay = years.map((item, index) => {
			return (
				<React.Fragment key={index}>
					<YearItem s="smaller" c="main01" selected={item === year} onClick={() => setYear(item)}>
						{item}
					</YearItem>
					{index < years.length - 1 ?
						<>
							<Spacer s="small" />
							<Text s="smaller" c="main01">
								|
							</Text>
							<Spacer s="small" />
						</>
						: null}
				</React.Fragment>
			);
		}
	);

	return (
		<Comp>
			<YearsContainer>
				{yearsDisplay}
			</YearsContainer>
			<Spacer s="small" />
			<TagList data={data} />
		</Comp>
	);
};

export default Years;
