import React from "react";
import styled from "styled-components";
import Spacer from "../layout/Spacer";
import Text from "../editorial/Text";
import slugify from "slugify";

const Comp = styled.div`
  display: flex;
  flex-wrap: wrap;
	position: relative;
	z-index: 100;
`;

const TagLink = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled(Text)`
  text-transform: capitalize;
`;

const TagList = (props) => {
	const { data } = props;

	const tags = data.map((files, index) => {
		return (
			<TagLink key={index}>
				<Title s="smaller" c="main01" as="a" href={"#" + slugify(files.title)}>
					{files.title}
				</Title>
				<Spacer s="medium" />
			</TagLink>
		);
	});

	return (
		<Comp {...props}>
			{tags}
		</Comp>
	);
};

export default TagList;
